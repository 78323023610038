import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Seo from '../components/Seo';

const BioContainer = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
);

const Biography = styled('div')(
  ({ theme }) => ({
    width: '450px',
    paddingLeft: theme.spacing(3),
  })
);

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>Brandon Khaw</Typography>
    <BioContainer>
      <StaticImage
        src="../images/20.jpg"
        formats={['AUTO', 'JPG']}
        alt="Brandon Khaw"
        width={310}
      />
      <Biography>
        <Typography gutterBottom={true} variant="subtitle1">
          Brandon Khaw was born in Rangoon, Burma, and many know him as Khin Maung Win, a rather common Burmese name.
          He has been drawing since kindergarten, and gained more interest in artwork when he was in medical school.
          He was only able to create a few paintings during this time. In his spare time, he had the opportunity
          to observe painting by the late Paw Oo Thet, a modern and well-known Burmese artist of his time.
        </Typography>
        <Typography gutterBottom={true} variant="subtitle1">
          He immigrated to the US in 1983, and has practiced medicine as an urgent care physician in Orange County since 1989.
        </Typography>
        <Typography gutterBottom={true} variant="subtitle1">
          He has mostly studied art from books and DVDs, and has also taken classes and workshops from renowned artists.
          He is interested in the representational arts, especially impressionism, plein air landscape, portraiture, and sculpture.
        </Typography>
      </Biography>
    </BioContainer>

  </>
);

export default IndexPage;
